<template>
  <div>
    <div class="custom-heading-2">Nové poptávky</div>
    <apexchart width="100%" height="350" type="area" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'NewReservationsChart',
  data () {
    return {
      options: {
        chart: {
          id: 'vuechart-example'
        },
        theme: {
          mode: 'light',
          palette: 'palette2'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.8,
            opacityFrom: 0.7,
            opacityTo: 0.8,
            stops: [0, 90, 100]
          }
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      series: [{
        name: 'Noví uživatelé',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }, {
        name: 'Noví hostitelé',
        data: [10, 40, 25, 10, 75, 60, 80, 111]
      }]
    }
  }
}
</script>

<style scoped>

</style>
