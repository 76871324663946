<template>
  <NewReservationsChart></NewReservationsChart>
</template>

<script>
import NewReservationsChart from '../../components/charts/NewReservationsChart'
export default {
  name: 'Dashboard',
  components: { NewReservationsChart }
}
</script>

<style scoped>

</style>
